import { Typography, Box, Container, Button } from "@mui/material";
import { GetApp } from "@mui/icons-material";
import React from "react";

function GDPR() {
  const downloadFile = () => {
    window.open("pdf_files/gdpr_fr.pdf", "_blank");
  };
  return (
    <Container>
      <Typography variant="body1" style={{ paddingTop: 0, fontWeight: "bold" }}>
        RGPD et Politique de Confidentialité
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Vous trouverez ci-dessous un aperçu général des données que nous
        collectons pour le fonctionnement de notre réseau de chaleur. Ce site
        web en lui-même ne collecte aucune donnée. Pour une description plus
        détaillée de nos pratiques de gestion des données et de notre conformité
        au RGPD, vous pouvez télécharger notre politique de confidentialité
        ci-dessous.
      </Typography>
      <Typography variant="h2" component="h2">
        Surveillance des performances du réseau de chaleur et de la consommation
        d'énergie
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Nous collectons des données sur les performances du réseau de chaleur et
        la consommation d'énergie pour assurer un fonctionnement correct et
        identifier des opportunités d'optimisation de l'utilisation de
        l'énergie. Les données sont collectées à l'aide de compteurs de chaleur
        connectés à un réseau LoRaWAN et sont stockées sur un serveur sécurisé
        sans aucune information personnellement identifiable.
      </Typography>
      <Typography variant="h2" component="h2">
        Facturation automatisée
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Pour simplifier le processus de facturation, nous utilisons les mêmes
        données collectées à des fins de surveillance pour calculer la
        consommation d'énergie et générer des factures pour les clients. Seules
        les données agrégées mensuelles sont utilisées à cette fin.
      </Typography>
      <Typography variant="h2" component="h2">
        Factures, paiements, reçus et communication avec les clients
      </Typography>
      <Typography>
        Nous utilisons un service tiers, Odoo, pour gérer la communication avec
        les clients, la facturation et les questions financières. Nous
        collectons et stockons des informations sur les clients telles que le
        nom, l'adresse, les coordonnées, les coordonnées bancaires et les
        documents contractuels. Les données sont traitées par Odoo selon leur
        <a
          href="https://www.odoo.com/fr_FR/privacy"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          politique de confidentialité{" "}
        </a>{" "}
        qui est conforme au RGPD.
      </Typography>
      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<GetApp />}
          onClick={downloadFile}
        >
          Télécharger le document RGPD complet
        </Button>
      </Box>
    </Container>
  );
}

export default GDPR;
