import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import { PersonOutlineOutlined } from "@mui/icons-material";
function Societe() {
  return (
    <div>
      <Typography variant="body1" style={{ paddingTop: 0, fontWeight: "bold" }}>
        Conseil d'administration
      </Typography>
      <Typography variant="body1">
        <br />
        Administrateur Délégué:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <PersonOutlineOutlined />
          </ListItemIcon>
          <ListItemText
            primary="Monsieur Gérard Meyer"
            secondary="Ministère de l'Énergie et de l'Aménagement du territoire"
          />
        </ListItem>
      </List>
      <Typography variant="body1">
        {" "}
        <br />
        Administrateurs :
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <PersonOutlineOutlined />
          </ListItemIcon>
          <ListItemText
            primary="Monsieur Meris Sehovic"
            secondary="Ville d'Esch-sur-Alzette"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PersonOutlineOutlined />
          </ListItemIcon>
          <ListItemText
            primary="Monsieur Dirk Kintzinger"
            secondary="Ministère du Logement"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PersonOutlineOutlined />
          </ListItemIcon>
          <ListItemText
            primary="Monsieur Jean Offermann"
            secondary="Ministère de l'Economie"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PersonOutlineOutlined />
          </ListItemIcon>
          <ListItemText
            primary="Monsieur Fabrice Pompignoli"
            secondary="Administration de l'Environnement"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PersonOutlineOutlined />
          </ListItemIcon>
          <ListItemText
            primary="Madame Franca Romeo"
            secondary="Commune de Sanem"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PersonOutlineOutlined />
          </ListItemIcon>
          <ListItemText
            primary="Monsieur Georges Peiffer"
            secondary="Ministère des Finances"
          />
        </ListItem>
      </List>
      <Typography variant="body1">
        {" "}
        <br />
        Observateur :
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <PersonOutlineOutlined />
          </ListItemIcon>
          <ListItemText primary="Monsieur Yves Biwer" secondary="Agora" />
        </ListItem>
      </List>
    </div>
  );
}

export default Societe;
