import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import reseau from "../../images/reseau.jpg";
import { Place } from "@mui/icons-material";

function Reseau() {
  return (
    <div>
      <Typography variant="body1" style={{ paddingTop: 0, fontWeight: "bold" }}>
        Réseau de chauffage urbain
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Le réseau de chauffage urbain assure la distribution de la chaleur de
        son lieu de production vers les différents consommateurs. Le réseau de
        chauffage atteint une longueur totale de 20 km et alimente notamment les
        quartiers suivants:
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <List>
            <ListItem>
              <ListItemIcon>
                <Place />
              </ListItemIcon>
              <ListItemText primary="Terrasse des Hauts Fourneaux" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Place />
              </ListItemIcon>
              <ListItemText primary="Square Mile" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Place />
              </ListItemIcon>
              <ListItemText primary="Belval Nord" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Place />
              </ListItemIcon>
              <ListItemText primary="Belval Sud" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Place />
              </ListItemIcon>
              <ListItemText primary="Z.A. Sommet" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Place />
              </ListItemIcon>
              <ListItemText primary="Nonnewisen" />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6}>
          <img
            src={reseau}
            alt="Réseau de chauffage urbain"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Typography variant="body1" paragraph={true}>
        Le réseau est constitué de tuyaux enterrés en acier pré-isolés avec une
        isolation en polyuréthane et un manteau protecteur en polyéthylène
        (PEHD). Ce système présente une très bonne résistance mécanique et
        supporte des températures élevées. La qualité des soudures est vérifiée
        par rayons X et par épreuves de pression. Un système de détection de
        fuites permet un monitoring continu du réseau.
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Les conduites sont posées avec un recouvrement de 80 à 130 cm.
      </Typography>
    </div>
  );
}

export default Reseau;

// RESEAU DE CHAUFFAGE URBAIN

// Le réseau de chauffage urbain assure la distribution de la chaleur de son lieu de production vers les différents consommateurs. Le réseau de chauffage atteint une longueur totale de 20 km et alimente notamment les quartiers suivants:

//     Terrasse des Hauts Fourneaux
//     Square Mile
//     Belval Nord
//     Belval Sud
//     Z.A. Sommet
//     Nonnewisen

// Le réseau est constitué de tuyaux enterrés en acier pré-isolés avec une isolation en polyuréthane et un manteau protecteur en polyéthylène (PEHD). Ce système présente une très bonne résistance mécanique et supporte des températures élevées. La qualité des soudures est vérifiée par rayons X et par épreuves de pression. Un système de détection de fuites permet un monitoring continu du réseau.

// Les conduites sont posées avec un recouvrement de 80 à 130 cm.
