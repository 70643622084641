import * as React from "react";
import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from "react-router-dom";

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";

type StrDict = Record<string, string>;
type ConfigDict = Record<string, string | StrDict>;

const NavigationStructure: ConfigDict = {
  Accueil: "/",
  Société: {
    Société: "/societe/societe",
    "Conseil d'administration": "/societe/conseil-dadministration",
    Historique: "/societe/historique",
  },
  Activités: {
    Production: "/activites/production",
    Réseau: "/activites/reseau",
    Sécurité: "/activites/securite",
    // Distribution: "/activites/distribution",
  },
  Administration: "/administration",
  Téléchargements: "/telechargements",
  Contacts: "/contacts",
  Transparence: "/transparence",
} as const;

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  pl?: number;
}

const NavLink = React.forwardRef<HTMLAnchorElement, RouterNavLinkProps>(
  function NavLink(itemProps, ref) {
    return <RouterNavLink ref={ref} {...itemProps} role="button" />;
  }
);

function ListItemLink(props: ListItemLinkProps | any) {
  const { icon, primary, to, pl = 0, onClose } = props;

  return (
    <ListItemButton
      component={NavLink}
      to={to}
      sx={{ pl: pl }}
      onClick={() => {
        console.log("onClose", onClose);
        onClose();
      }}
    >
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText
        primary={primary}
        sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
      />
    </ListItemButton>
  );
}
function TopLevelLink(label: string, destination: string, onClose: () => void) {
  return (
    <ListItemLink to={destination} primary={label} pl={2} onClick={onClose} />
  );
}

function SubLevelLink(label: string, destination: string, onClose: () => void) {
  return (
    <ListItemLink to={destination} primary={label} pl={4} onClick={onClose} />
  );
}

function ListLinkElement(
  label: string,
  sublabels: StrDict,
  onClose: () => void
) {
  // Render the sublinks.
  // Map the sublabels to the rendered sublinks.
  let rendered = [];
  for (let sublabel in sublabels) {
    rendered.push(SubLevelLink(sublabel, sublabels[sublabel], onClose));
  }

  return (
    <>
      <ListSubheader component="div" id="nested-list-subheader">
        {label}
      </ListSubheader>
      <List component="div" disablePadding>
        {rendered}
      </List>
    </>
  );
}

function renderNavLinks(
  mainLabel: string,
  sublabels_or_path: string | StrDict,
  onClose: () => void
) {
  // If the sublabels_or_path is a string, then it is a path to a page.
  if (typeof sublabels_or_path === "string") {
    return TopLevelLink(mainLabel, sublabels_or_path, onClose);
  }

  // Otherwise, it is a dictionary of sublabels and paths.
  return ListLinkElement(mainLabel, sublabels_or_path, onClose);
}
function NavMenu({ onClose }: { onClose: () => void }) {
  let rendered = [];
  for (let mainLabel in NavigationStructure) {
    let sublabels_or_path = NavigationStructure[mainLabel];
    rendered.push(renderNavLinks(mainLabel, sublabels_or_path, onClose));
  }

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        borderRight: 1,
        borderRightColor: "divider",
        pr: 2,
        mr: 2,
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {rendered}
    </List>
  );
}
export default NavMenu;
