import { Divider, Paper, Typography, Drawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import { Link, Outlet } from "react-router-dom";
import NavMenu from "../NavMenu";
import sudcal_logo from "../sudcal_logo.svg";
import "./App.css";
import { useState } from "react";

function Index() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <NavMenu onClose={handleDrawerToggle} />
    </div>
  );
  return (
    <>
      <Container>
        <div style={{ textAlign: "center" }}>
          <img src={sudcal_logo} alt="Sudcal logo" />
        </div>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
        <Divider sx={{ my: 2 }} />
        <Paper elevation={3} sx={{ p: 2, height: "100%" }}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={3}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              {drawer}
            </Grid>
            <Grid item xs={12} sm={9}>
              <Outlet />
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <footer className="footer" style={{ paddingTop: "1rem" }}>
        <div className="footer__container">
          <div className="footer__links" style={{ textAlign: "center" }}>
            <Link to="/privacy-policy" color="text.secondary">
              <Typography variant="body2" color="text.secondary" align="center">
                Vie Privée et Gestion des Données Personnelles
              </Typography>
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Index;
