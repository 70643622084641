import { Typography } from "@mui/material";

function Societe() {
  return (
    <div>
      <Typography variant="body1" style={{ paddingTop: 0, fontWeight: "bold" }}>
        Société
      </Typography>
      <Typography variant="body1" paragraph={true}>
        <p>
          La société Sudcal a comme objet la réalisation et l'exploitation d'un
          réseau de chauffage urbain. Sudcal a commencé ses travaux au début du
          printemps 2002 sous le statut d’un groupement d'intérêt économique
          (GIE).
          <br />
          <br />
          En mars 2008 une société anonyme dénommée "Sudcal S.A."a été créée. Le
          capital de Sudcal S.A. est détenu par l'Etat (3.098 actions), la Ville
          d'Esch-sur-Alzette (1 action) et la Commune de Sanem (1 action).
        </p>
      </Typography>
      {/* <Typography variant="body1" paragraph={true}>
        Dans le souci de réduire les émissions de CO2 et de valoriser le
        potentiel énergétique de la centrale turbine gaz-vapeur (TGV) de Twinerg
        S.A., la construction d'un réseau de chaleur à Esch-sur-Alzette et
        environs immédiats de la centrale TGV avait été discutée dès la mise en
        opération de la centrale TGV. L'exploitation d'un réseau de chaleur
        devait donc permettre à Twinerg l'utilisation rationnelle de la chaleur
        issue de sa centrale électrique.
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Cette constellation avait été retenue en vue du projet Agora présentant
        de très intéressantes opportunités pour l'établissement d'un réseau de
        chauffage urbain. En outre, la ville d'Esch-sur-Alzette, le syndicat
        ZARE et l'Etat poursuivaient également des projets immobiliers qui
        seraient à raccorder au réseau de chaleur.
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Sudcal, d'abord dans le statut d'un groupement d'intérêt économique
        (GIE), a commencé ses travaux au début du printemps 2002. Au vu des
        résultats positifs des études, le GIE Sudcal a par conséquent décidé de
        poursuivre le projet et s'est ensuite engagé à fournir de la chaleur aux
        clients finals à partir de fin 2004.
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Afin de permettre la réalisation et l'exploitation d'un réseau cohérent
        de chauffage urbain alimenté par la centrale Twinerg, le Gouvernement a
        donné en mars 2008 son feu vert à la création d'une société anonyme
        dénommée "Sudcal S.A.", propriétaire du réseau de chaleur ainsi que des
        équipements accessoires et exploitant de ce même réseau. Le capital de
        Sudcal S.A. est détenu par l'Etat (3.098 actions), la Ville
        d'Esch-sur-Alzette (1 action) et la Commune de Sanem (1 action).
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Après les analyses positives, AMBD et Sudcal se sont engagées à réaliser
        un projet de récupération d'énergie sur le site Belval qui est devenu
        opérationnel en mai 2018. Sudcal peut ainsi couvrir à nouveau la
        majorité de ses besoins en énergie thermique par le biais de chaleurs
        dissipées et retrouve sa vocation originelle d'un réseau de chaleur
        respectueux de l'environnement.
      </Typography>
    </div> */}
    </div>
  );
}

export default Societe;
