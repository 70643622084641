import { Typography } from "@mui/material";

function Administration() {
  return (
    <div>
      <Typography variant="body1" style={{ paddingTop: 0, fontWeight: "bold" }}>
        Contrat de fourniture
      </Typography>
      <Typography variant="body1" paragraph>
        Chaque propriétaire d'un immeuble a conclu un contrat avec Sudcal pour
        la fourniture de chaleur à son immeuble. Ce contrat définit les
        conditions de fourniture de chaleur (par exemple les températures de
        fourniture et de retour de chaleur), la puissance thermique réservée par
        le client et les tarifs pour l’énergie et la puissance thermique.
      </Typography>
      <Typography variant="body1" style={{ paddingTop: 0, fontWeight: "bold" }}>
        Déménagement{" "}
      </Typography>
      <Typography variant="body1" paragraph>
        Lors de la vente d’un immeuble raccordé au réseau de chauffage de
        Sudcal, le propriétaire doit informer les candidats acquéreurs de
        l'existence du raccordement au réseau de chauffage. Le contrat de
        fourniture de chaleur doit être transféré par le propriétaire à
        l'acheteur du bien. Le vendeur informe immédiatement Sudcal de ce
        transfert. Une visite des lieux doit être organisée au cours de laquelle
        l'acheteur et le vendeur Sudcal enregistrent ensemble le relevé du
        compteur de chaleur.
      </Typography>
      {/* <Typography variant="body1" style={{ paddingTop: 0, fontWeight: "bold" }}>
        Tarifs
      </Typography>
      <Typography variant="body1" paragraph>
        <ul>
          <li>
            Les tarifs de l'énergie et de la puissance thermique sont fixés dans
            le contrat de fourniture et peuvent évoluer mensuellement en
            fonction des variations des indices sous-jacents.{" "}
          </li>
          <li>La facturation de la chaleur fournie se fait :</li>
          <ul>
            <li>
              sur une base mensuelle pour les clients ayant une puissance
              thermique installée supérieure à 30 kW ;
            </li>
            <li>
              sur une base mensuelle ou annuelle pour les clients ayant une
              puissance thermique installée inférieure ou égale à 30 kW. Dans ce
              dernier cas, un profil type est pris en compte comme décrit dans
              le document…
            </li>
          </ul>
        </ul>
      </Typography> */}
    </div>
  );
}

export default Administration;
