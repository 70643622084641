import { Typography } from "@mui/material";

function Securite() {
  return (
    <div>
      <Typography variant="body1" style={{ paddingTop: 0, fontWeight: "bold" }}>
        Securite d'approvisionnement
      </Typography>
      <Typography variant="body1">
        Pour le client final, la sécurité d'approvisionnement du réseau de
        chauffage urbain est au moins équivalente à celle d'une installation de
        chauffage classique décentralisée:
      </Typography>
      <ul>
        <li>
          la gestion des équipements de Sudcal est assurée par un exploitant
          professionnel avec une surveillance 24h/24h des installations;
        </li>
        <li>
          la cascade de plusieurs systèmes de production de chaleur (chaleur
          récupérée d'un site industriel, chaudière à gaz, chaudières
          supplémentaires au mazout) offre plusieurs possibilités de back-up et
          de tamponnage, capables de prendre la relève en cas d'avarie d'un de
          ces systèmes;
        </li>
        <li>
          les équipements installés dans la station de pompage profitent d'une
          alimentation électrique par groupe de secours ce qui permet d'assurer
          la disponibilité de la chaleur urbaine, même en cas de coupure de
          courant sur le réseau public;
        </li>
        <li>
          le seul élément non redondant du concept est le réseau de chauffage
          urbain, lui-même peu vulnérable, Les risques d'un endommagement
          sérieux du réseau se limitent à des travaux de terrassement à la pelle
          mécanique.
        </li>
      </ul>
    </div>
  );
}

export default Securite;
