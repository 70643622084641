import { Typography } from "@mui/material";

function Historique() {
  return (
    <div>
      <Typography variant="body1" style={{ paddingTop: 0, fontWeight: "bold" }}>
        Historique
      </Typography>
      <Typography variant="body1" paragraph={true}>
        La construction du réseau de chaleur et des équipements annexes
        permettant le soutirage de chaleur à partir de la centrale de Twinerg se
        faisait en parallèle. Les premiers clients ont été approvisionnés en
        chaleur dès la fin 2004 par des chaudières mobiles alimentant au fur et
        à mesure les quartiers peuplés.
      </Typography>
      <Typography variant="body1" paragraph={true}>
        En 2010, les travaux d'installation dans la station de pompage
        (chaudière back-up, pompes réseau, ballons tampon, cheminée) ont été
        réalisés.
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Les essais de mise en service des échangeurs chez Twinerg ont été
        effectués en janvier 2011, et l'alimentation continue du réseau par
        Twinerg était effective à partir du mois de février 2011.
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Dès l'annonce des actionnaires de Twinerg de vouloir fermer la centrale
        TGV en raison de facteurs économiques liés au marché de l'électricité,
        Sudcal a aussitôt commencé à évaluer des scénarios alternatifs de mise à
        disposition de chaleur à partir de sources d'énergie renouvelables afin
        de pouvoir continuer à fournir de la chaleur écologique à ses clients.
      </Typography>
      <Typography variant="body1" paragraph={true}>
        A la fin de 2016, une étude de faisabilité a été réalisée par les
        responsables d'ArcelorMittal Belval & Differdange (AMBD) ensemble avec
        Sudcal afin d'analyser le potentiel pour la valorisation d'énergies
        dissipées sur le site Belval d'AMBD en vue d'alimenter le réseau de
        chaleur de Sudcal.
      </Typography>
      <Typography variant="body1" paragraph={true}>
        Après les analyses positives, AMBD et Sudcal se sont engagées à réaliser
        un projet de récupération d'énergie sur le site Belval qui deviendra
        opérationnel dans la première moitié de 2018. Sudcal pourra ainsi
        couvrir à nouveau la majorité de ses besoins en énergie thermique par le
        biais de chaleurs dissipées et retrouve sa vocation originelle d'un
        réseau de chaleur respectueux de l'environnement.
      </Typography>
    </div>
  );
}

export default Historique;
