import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
// PDF icon:
import { PictureAsPdf } from "@mui/icons-material";
function Telechargements() {
  return (
    <div>
      <Typography variant="body1" style={{ paddingTop: 0, fontWeight: "bold" }}>
        Téléchargements
      </Typography>
      {/* <ul>
        <li>
          <a
            href="sites/default/files/inline-files/Sudcal_20190603_Formulaire&#32;de&#32;mise&#32;en&#32;service.pdf"
            title="Formulaire de mise en service"
          >
            Formulaire de demande de mise en service
          </a>
        </li>
        <li>
          <a href="sites/default/files/inline-files/Note&#32;relative&#32;au&#32;passeport&#32;énergétique&#32;pour&#32;les&#32;bâtiments&#32;connectes&#32;au&#32;r....pdf">
            Note relative au passeport énergétique
          </a>{" "}
        </li>
        <li>
          <a
            href="sites/default/files/inline-files/Information&#32;bezüglich&#32;Energiepass&#32;für&#32;die&#32;Gebäude&#32;die&#32;an&#32;das&#32;Fernwarmene....pdf"
            target="_blank"
          >
            Information bezüglich Energiepass
          </a>
        </li>
        <li>
          <a
            href="sites/default/files/inline-files/Consignes-pour-travaux-dans-les-environs-d-ouvrages-souterrains.pdf"
            target="_blank"
            title="Consignes à respecter lors de travaux dans les environs d’ouvrages souterrains de Sudcal"
          >
            Consignes à respecter lors de travaux dans les environs d’ouvrages
            souterrains de Sudcal
          </a>
        </li>
      </ul> */}
      <List>
        <ListItem
          button
          component="a"
          href="pdf_files/Sudcal_conditions_de_raccordement.pdf"
          target="_blank"
        >
          <ListItemIcon>
            <PictureAsPdf />
          </ListItemIcon>
          <ListItemText primary="Conditions de raccordement" />
        </ListItem>
        <ListItem
          button
          component="a"
          href="pdf_files/Sudcal_20190603_Formulaire&#32;de&#32;mise&#32;en&#32;service.pdf"
          title="Formulaire de mise en service"
          target="_blank"
        >
          <ListItemIcon>
            <PictureAsPdf />
          </ListItemIcon>
          <ListItemText primary="Formulaire de demande de mise en service" />
        </ListItem>
        {/* <ListItem
          button
          component="a"
          href="pdf_files/Note&#32;relative&#32;au&#32;passeport&#32;energetique&#32;pour&#32;les&#32;batiments&#32;connectes&#32;au&#32;reseau.pdf"
          target="_blank"
        >
          <ListItemIcon>
            <PictureAsPdf />
          </ListItemIcon>
          <ListItemText primary="Note relative au passeport énergétique" />
        </ListItem> */}
        {/* <ListItem
          button
          component="a"
          href="pdf_files/Information&#32;bezuglich&#32;Energiepass&#32;fur&#32;die&#32;Gebaude&#32;die&#32;an&#32;das&#32;Fernwarmene.pdf"
          target="_blank"
        >
          <ListItemIcon>
            <PictureAsPdf />
          </ListItemIcon>
          <ListItemText primary="Information bezüglich Energiepass" />
        </ListItem> */}
        <ListItem
          button
          component="a"
          href="pdf_files/Consignes-pour-travaux-dans-les-environs-d-ouvrages-souterrains.pdf"
          target="_blank"
          title="Consignes à respecter lors de travaux dans les environs d’ouvrages souterrains de Sudcal"
        >
          <ListItemIcon>
            <PictureAsPdf />
          </ListItemIcon>
          <ListItemText primary="Consignes à respecter lors de travaux dans les environs d’ouvrages souterrains de Sudcal" />
        </ListItem>
      </List>
    </div>
  );
}

export default Telechargements;
