import { Typography } from "@mui/material";
import { Box } from "@mui/system";

function Accueil() {
  return (
    <div>
      <Typography variant="h2" style={{ paddingBottom: 0 }}>
        Nouveautés
      </Typography>

      <Box sx={{ padding: 2 }}>
        {/* <Typography
          variant="body1"
          style={{ paddingTop: 0, fontWeight: "bold" }}
        >
          Extension du réseau à Square Miles Belval avec Lot 8, nouveau raccordement: Twist, Manhattan, etc
        </Typography>
        <Typography variant="body1" paragraph>
          Nouveau raccordement à Nonnewisen avec le SNHB, 3SE et 4SA.
        </Typography>
        <hr />
        <Typography
          variant="body1"
          style={{ paddingTop: 0, fontWeight: "bold" }}
        >
          Optimisation hydraulique du réseau
        </Typography> */}

        <Typography variant="body1" paragraph>
          Sudcal collabore avec l'Université de Luxembourg et Hysopt pour
          optimiser l'efficacité hydraulique de son réseau de chaleur. Dans ce
          cadre, un jumeau numérique a été développé, permettant une simulation
          hydraulique et thermodynamique du réseau. Ce modèle permet également
          d'identifier des mesures pour réduire l'empreinte énergétique des
          systèmes de chauffage et de climatisation. Plus d'information sur{" "}
          <a
            href="https://hysopt.atlassian.net/wiki/spaces/HRM/overview"
            target="_blank"
            rel="noopener noreferrer"
          >
            hysopt wiki
          </a>
        </Typography>
        <hr />
        <Typography
          variant="body1"
          style={{ paddingTop: 0, fontWeight: "bold" }}
        >
          Réduction des températures du réseau
        </Typography>
        <Typography variant="body1" paragraph>
          Les réseaux de chaleur à basse température présentent l'avantage de
          limiter les pertes de chaleur et d’intégrer plus facilement de sources
          de chaleur d’origine renouvelable. Actuellement, Sudcal est un réseau
          de 3ème génération qui fonctionne à des températures relativement
          élevées. Cependant, une transition est en cours vers un réseau de 4ème
          génération est en cours d’étude. Sudcal travaille activement à la
          réduction des températures de son réseau tout en veillant à garantir
          la fourniture de chaleur à ses clients: Cette démarche permettra de
          bénéficier des avantages d'un réseau de chaleur à basse température,
          et ainsi de réduire l'empreinte carbone du réseau. Sudcal travaille
          activement à la réduction des températures de son réseau toute en
          veillant à garantir la fourniture de la chaleur à ses clients:
          <ul>
            <li>
              la température sur le réseau à été réduite en été de 95°C à 85°C;
            </li>
            <li>
              les clients sont demandées de respecter leur limite contractuelle
              de température:
              <ul>
                <li>50°C pour les clients raccordés avant 2022;</li>
                <li>40°C pour les clients raccordés après 2022.</li>
              </ul>
            </li>
          </ul>
        </Typography>
        <hr />
        <Typography
          variant="body1"
          style={{ paddingTop: 0, fontWeight: "bold" }}
        >
          Optimisation des installations de chauffage et production d’eau chaude
          sanitaire (ECS) de nos clients{" "}
        </Typography>
        {/* add horizontal line */}

        <Typography variant="body1" paragraph>
          Nous étudions également avec les clients intéressés les possibilités
          d'optimiser leurs installations. Une telle optimisation peut aider à
          diminuer la consommation d'énergie et donc aussi la facture du client
          final sans perte de confort. Ceci implique à la fois à l'optimisation
          hydraulique et à l'optimisation thermique. Quelques exemples
          d’intervention :
          <ul>
            <li>
              une révision de la régulation existante des installations
              techniques ;
            </li>
            <li>la suppression des bypass hydrauliques inutiles ;</li>
            <li>la réduction des températures des circuits.</li>
          </ul>
          <p>
            Intéressé ? N’hésitez pas de prendre contact avec notre service
            technique: info@sudcal.lu
          </p>
        </Typography>
      </Box>
    </div>
  );
}

export default Accueil;
