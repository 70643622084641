import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { ComponentProps } from "react";

type StatusKey = "ok" | "warning" | "error";

type BackgroundColor = NonNullable<
  ComponentProps<"div">["style"]
>["backgroundColor"];

type StatusColors = {
  [key in StatusKey]: BackgroundColor;
};

const statusColors: StatusColors = {
  ok: "lightgreen",
  warning: "yellow",
  error: "red",
};
function Transparence() {
  const [status, setStatus] = useState<StatusKey>("ok");
  const [titreBanniere, setBannerTitle] = useState("Chargement du titre...");
  const [corpsBanniere, setBannerMessage] = useState("Chargement du statut...");
  useEffect(() => {
    async function fetchData() {
      try {
        const responseStatus = await fetch("banner_status.txt");
        if (!responseStatus.ok) {
          throw new Error("Erreur lors du chargement de banner_status.txt");
        }
        const textStatus = await responseStatus.text();
        setStatus((textStatus.trim() as StatusKey) || "ok");

        const responseBannerTitle = await fetch("banner_title.txt");
        if (!responseBannerTitle.ok) {
          throw new Error("Erreur lors du chargement de banner_title.txt");
        }
        const textBannerTitle = await responseBannerTitle.text();
        setBannerTitle(textBannerTitle);

        const responseBannerMessage = await fetch("banner_message.txt");
        if (!responseBannerMessage.ok) {
          throw new Error("Erreur lors du chargement de banner_message.txt");
        }
        const textBannerBody = await responseBannerMessage.text();
        setBannerMessage(textBannerBody);
      } catch (error) {
        console.error(error);
        setStatus("error");
        setBannerTitle("Une erreur s'est produite.");
        setBannerMessage(
          "Une erreur est survenue lors de la récupération des informations de la bannière."
        );
      }
    }

    fetchData();
  }, []);
  return (
    <div>
      <Typography variant="body1" style={{ paddingTop: 0, fontWeight: "bold" }}>
        État du Réseau
      </Typography>

      <Box
        sx={{
          borderColor: statusColors[status],
          borderRadius: 2,
          borderStyle: "solid",
          borderWidth: 2,
          padding: 2,
          margin: 2,
        }}
      >
        <Typography variant="h6">{titreBanniere}</Typography>
        <Typography variant="body1">{corpsBanniere}</Typography>
      </Box>
    </div>
  );
}

export default Transparence;
