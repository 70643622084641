import React from "react";
import pumping_station from "../../images/pumping_station.jpg";
import arcelor_station from "../../images/station_arcelor.jpg";
import { Typography, Grid } from "@mui/material";

function Centrale() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            style={{ paddingTop: 0, fontWeight: "bold" }}
          >
            {" "}
            La station de récupération de chaleur fatale du site d'ArcelorMittal
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <img
            src={arcelor_station}
            alt="Station de pompage"
            style={{ maxWidth: "100%" }}
          />
        </Grid>
      </Grid>

      <Typography variant="body1" paragraph={true}>
        La chaleur provenant du laminoir Train 2 d'ArcelorMittal site Belval est
        récupérée grâce à un échangeur de chaleur (fumées/eau), installé entre
        le four à longerons et la cheminée du four. Cet échangeur permet de
        chauffer un réseau d'eau chaude primaire qui lui est connecté à deux
        échangeurs de chaleur (eau/eau), situés dans une sous-station installée
        sur la limite des terrains d'ArcelorMittal et le Fonds Belval, et
        alimentant le réseau de chaleur urbain de Sudcal.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            style={{ paddingTop: 0, fontWeight: "bold" }}
          >
            La centrale de Sudcal
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <img
            src={pumping_station}
            alt="Station de pompage"
            style={{ maxWidth: "100%" }}
          />
        </Grid>
      </Grid>

      <Typography variant="body1" paragraph={true}>
        <p>
          La centrale de Sudcal, ou station de pompage, est implantée à
          Esch-Raemerich.
        </p>
        <p>Elle abrite :</p>
        <ul>
          <li>une chaudière de pointe et de back-up ;</li>
          <li>
            les différents groupes de pompes pour l'alimentation du réseau
            assure la circulation de l'eau dans le réseau (circuit fermé) ;
          </li>
          <li>
            le système d'expansion pour compenser les effets de dilatation de
            l'eau du réseau ;
          </li>
          <li>
            trois ballons tampon servant de stockage à court terme d'énergie
            thermique afin de pouvoir compenser des pointes et des variations de
            charge ;
          </li>
          <li>un groupe électrogène de secours.</li>
        </ul>
        <p>
          Deux chaudières supplémentaires fonctionnant exclusivement au mazout
          assurent le back-up subsidiaire pour les cas où la chaudière à gaz
          tomberait en panne et le système de récupération de chaleur fatale
          d'ArcelorMittal ne pourrait pas livrer la quantité de chaleur
          nécessaire.
        </p>
        <p>La station de pompage a été mise en service en décembre 2010.</p>
      </Typography>
    </div>
  );
}

export default Centrale;
