// components/NotFound.jsx
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

function NotFound() {
  return (
    <div>
      <Typography variant="h1">404</Typography>
      <Typography variant="h4">Page Non Trouvée</Typography>
      <Typography variant="body1">
        La page que vous avez demandée n'existe pas.
      </Typography>
      <Link to="/">Retour à l'accueil</Link>
    </div>
  );
}

export default NotFound;
