import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./routes/App";
import reportWebVitals from "./reportWebVitals";
import Accueil from "./routes/Accueil";
import Societe from "./routes/societe/Societe";
import ConseilAdministration from "./routes/societe/ConseilAdministration";
import Historique from "./routes/societe/Historique";
import Production from "./routes/activites/Production";
import Reseau from "./routes/activites/Reseau";
import Securite from "./routes/activites/Securite";
import Administration from "./routes/Administration";
// import Fonctionnement from "./routes/Fonctionnement";
import Telechargements from "./routes/Telechargements";
import Contacts from "./routes/Contacts";
import Transparence from "./routes/Transparence";
import NotFound from "./routes/NotFound";
import GDPR from "./routes/GDPR";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { pink } from "@mui/material/colors";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Accueil /> },
      { path: "accueil", element: <Accueil /> },
      { path: "societe/societe", element: <Societe /> },
      {
        path: "societe/conseil-dadministration",
        element: <ConseilAdministration />,
      },
      { path: "societe/historique", element: <Historique /> },
      { path: "activites/production", element: <Production /> },
      { path: "activites/reseau", element: <Reseau /> },
      { path: "activites/securite", element: <Securite /> },
      // { path: "activites/distribution", element: <Distribution /> },
      { path: "administration", element: <Administration /> },
      { path: "telechargements", element: <Telechargements /> },
      { path: "contacts", element: <Contacts /> },
      { path: "transparence", element: <Transparence /> },
      { path: "privacy-policy", element: <GDPR /> },
      { path: "*", element: <NotFound /> }, // Add a fallback route with the NotFound componentO
    ],
  },
]);

// Highlight color: 2d983f

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d983f",
    },
    secondary: pink,
  },
  typography: {
    h1: {
      fontSize: "2.5rem",
      paddingBottom: "1rem",
      paddingTop: 8,
    },
    h2: {
      fontSize: "2rem",
      paddingBottom: "1rem",
    },
    h3: {
      fontSize: "1.5rem",
      paddingBottom: "1rem",
    },
  },
  // Make grid items have padding:
  components: {
    MuiGrid: {
      styleOverrides: {
        item: {
          padding: "1rem",
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
